import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SealerImage from "../Img/Sealer.png";

const TypewriterEffect = ({ text, delay = 100 }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white py-20">
        <div className="container mx-auto px-4 text-center" data-aos="fade-up">
          <h1
            className="text-4xl md:text-6xl font-bold mb-4 transition-opacity duration-1000 ease-in-out"
            style={{ opacity: isVisible ? 1 : 0 }}
          >
            <TypewriterEffect text="Leverage Webtools Today!" />
          </h1>
          <p
            className="text-xl md:text-2xl mb-8"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Crafting Digital Experiences
          </p>
          <a href="/Services">
            <button
              className="bg-white text-blue-600 font-bold py-2 px-4 rounded-full hover:bg-blue-100 transition duration-300 transform hover:scale-105"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              Explore Our Services
            </button>
          </a>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2
            className="text-4xl font-bold mb-12 text-center text-gray-800"
            data-aos="fade-up"
          >
            Our Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Web Development",
                description:
                  "Custom, responsive websites and web applications built with cutting-edge technologies.",
                icon: "🖥️",
              },
              {
                title: "UI/UX Design",
                description:
                  "User-centric designs that enhance engagement and deliver exceptional user experiences.",
                icon: "🎨",
              },
              {
                title: "Digital Marketing",
                description:
                  "Data-driven strategies to boost your online presence and drive measurable results.",
                icon: "📈",
              },
              {
                title: "AI Integration",
                description:
                  "Leverage artificial intelligence to optimize your business processes and decision-making.",
                icon: "🤖",
              },
              {
                title: "Data Analytics",
                description:
                  "Transform raw data into actionable insights to drive your business forward.",
                icon: "📊",
              },
              {
                title: "Cloud Solutions",
                description:
                  "Scalable and secure cloud infrastructure to support your growing business needs.",
                icon: "☁️",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-2"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <div className="text-4xl mb-4">{service.icon}</div>
                <h3 className="text-2xl font-bold mb-4 text-gray-800">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <a
              href="/Contact"
              className="bg-blue-600 text-white px-8 py-3 rounded-full font-bold hover:bg-blue-700 transition duration-300"
              data-aos="fade-up"
            >
              Get a Free Consultation
            </a>
          </div>
        </div>
      </section>

      {/* Featured Project */}
      <section className="bg-gray-200 py-16">
        <div className="container mx-auto px-4">
          <h2
            className="text-3xl font-bold mb-8 text-center"
            data-aos="fade-up"
          >
            Featured Project
          </h2>
          <div className="bg-white rounded-lg shadow-xl overflow-hidden md:flex">
            <div
              className="md:w-1/2 relative overflow-hidden"
              data-aos="fade-right"
            >
              <img
                src={SealerImage}
                alt="Featured Project"
                className="w-full h-full object-cover absolute inset-0"
              />
            </div>
            <div className="md:w-1/2 p-8" data-aos="fade-left">
              <h3 className="text-2xl font-bold mb-4">Service Project</h3>
              <p className="mb-4">
                Service Project Hub is an all-in-one app for organizing,
                tracking, and collaborating on service projects. With a
                user-friendly interface, users can create projects, set goals,
                assign tasks, track milestones, and monitor progress in real
                time. Collaborative tools make it easy for team members to
                communicate and share updates. The app’s dashboard provides
                visual analytics, highlighting key metrics and project impact.
                Service Project Hub streamlines planning and execution.
              </p>

              <a
                href="https://groundspros.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                  View Case Study
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gradient-to-r from-indigo-700 to-blue-600 text-white py-16">
        <div className="container mx-auto px-4 text-center" data-aos="fade-up">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Transform Your Digital Presence?
          </h2>
          <p className="text-xl mb-8">
            Let's create something amazing together.
          </p>
          <a href="/Contact">
            <button
              className="bg-white text-blue-600 font-bold py-2 px-4 rounded-full hover:bg-blue-100 transition duration-300 transform hover:scale-105"
              data-aos="zoom-in"
            >
              Get Started
            </button>
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
