import React from "react";
import {
  FaCode,
  FaPaintBrush,
  FaChartLine,
  FaMobileAlt,
  FaServer,
  FaShieldAlt,
} from "react-icons/fa";
import { motion } from "framer-motion";

const ServiceCard = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
  >
    <div className="text-4xl text-blue-600 mb-4">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const Services = () => {
  const services = [
    {
      icon: <FaCode aria-hidden="true" />,
      title: "Web Development",
      description:
        "Custom web applications tailored to your business needs using the latest technologies.",
    },
    {
      icon: <FaPaintBrush aria-hidden="true" />,
      title: "UI/UX Design",
      description:
        "Creating intuitive and visually appealing interfaces for exceptional user experiences.",
    },
    {
      icon: <FaChartLine aria-hidden="true" />,
      title: "Digital Marketing",
      description:
        "Comprehensive digital marketing strategies to boost your online presence and reach.",
    },
    {
      icon: <FaMobileAlt aria-hidden="true" />,
      title: "Mobile App Development",
      description:
        "Developing robust and user-friendly mobile applications for iOS and Android platforms.",
    },
    {
      icon: <FaServer aria-hidden="true" />,
      title: "Cloud Solutions",
      description:
        "Scalable cloud infrastructure and migration services for improved efficiency and security.",
    },
    {
      icon: <FaShieldAlt aria-hidden="true" />,
      title: "Cybersecurity",
      description:
        "Protecting your digital assets with advanced cybersecurity measures and best practices.",
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-8"
        >
          Our Services
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xl text-center text-gray-600 mb-12"
        >
          Empowering your business with cutting-edge technology solutions
        </motion.p>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-16 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Need a custom solution?</h2>
          <p className="text-gray-600 mb-8">
            We specialize in tailoring our services to meet your unique business
            requirements.
          </p>
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            href="/contact"
            className="inline-block bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 transition duration-300"
          >
            Contact Us
          </motion.a>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
