import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logoImage from "../Img/Logo.png";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <header
      className="sticky top-0 bg-gray-800 p-4 shadow-lg z-50"
      data-aos="fade-down"
    >
      <div className="container mx-auto flex justify-between items-center">
        <div className="relative">
          <img
            src={logoImage}
            alt="WebTek Online Logo"
            className="h-8 w-auto mr-2 transition-transform duration-300 hover:scale-110"
            data-tooltip-id="logo-tooltip"
            data-tooltip-content="Transforming Ideas into Reality with Smart Technology!"
          />
          <Tooltip id="logo-tooltip" place="top" effect="solid" />
        </div>
        <nav>
          <ul className="flex space-x-4">
            <li data-aos="fade-left" data-aos-delay="100">
              <Link
                to="/"
                className="text-white hover:text-gray-400 transition-colors duration-300"
              >
                Home
              </Link>
            </li>
            <li data-aos="fade-left" data-aos-delay="200">
              <Link
                to="/Services"
                className="text-white hover:text-gray-400 transition-colors duration-300"
              >
                Services
              </Link>
            </li>
            <li data-aos="fade-left" data-aos-delay="300">
              <Link
                to="/Contact"
                className="text-white hover:text-gray-400 transition-colors duration-300"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
