import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("idle"); // idle, loading, success, error
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setStatus("loading");
    try {
      const response = await fetch("https://formspree.io/f/xnnqnzre", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result.ok) {
        setStatus("success");
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatus("error");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Contact Form */}
          <div className="md:w-1/2">
            <div className="bg-white p-8 rounded-lg shadow-lg h-full flex flex-col">
              <h2 className="text-3xl font-bold mb-6 text-center">
                Contact Us
              </h2>
              {status === "success" ? (
                <div className="text-green-600 text-center mb-4">
                  Thank you for your message. We'll get back to you soon!
                </div>
              ) : (
                <form className="flex-grow space-y-4" onSubmit={handleSubmit}>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        errors.name ? "border-red-500" : ""
                      }`}
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs italic">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        errors.email ? "border-red-500" : ""
                      }`}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs italic">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        errors.message ? "border-red-500" : ""
                      }`}
                      id="message"
                      name="message"
                      rows="5"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && (
                      <p className="text-red-500 text-xs italic">
                        {errors.message}
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      className={`bg-blue-600 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700 transition duration-300 ${
                        status === "loading"
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      type="submit"
                      disabled={status === "loading"}
                    >
                      {status === "loading" ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                  {status === "error" && (
                    <p className="text-red-500 text-center">
                      An error occurred. Please try again later.
                    </p>
                  )}
                </form>
              )}
            </div>
          </div>

          {/* Map */}
          <div className="md:w-1/2">
            <div className="bg-white p-8 rounded-lg shadow-lg h-full flex flex-col">
              <h2 className="text-3xl font-bold mb-6 text-center">
                Our Location
              </h2>
              <div className="flex-grow">
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder={0}
                  style={{ border: 0, minHeight: "300px" }}
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAdXBbxofcZ2URyhL7Sg05iysbn-52t3og&q=WebTek+Online"
                  allowFullScreen
                  title="WebTek Online Location Map"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
